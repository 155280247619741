/* GameUpload.css */
.game-upload-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
}

.game-upload-modal-content {
    background-color: #181818; /* Dark background for the modal content */
    padding: 20px;
    border-radius: 8px;
    width: 80%; /* Adjust as needed */
    max-width: 600px; /* Maximum width */
    color: #ffffff;
    position: relative; /* For close button positioning */
    overflow-y: auto; /* Enable scroll if content is long */
    max-height: 90vh; /* Max height to prevent modal from being too tall */
}

.game-upload-modal-title {
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff; /* White text color */
}

.game-upload-modal-close-button,
.game-upload-modal-back-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    color: #ffffff;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.game-upload-modal-back-button {
    left: 10px;
    right: auto;
}

.game-upload-modal-close-button:hover,
.game-upload-modal-back-button:hover {
    opacity: 1;
}

.game-upload-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
}

.game-upload-option {
    background-color: #282828; /* Slightly lighter background for options */
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.game-upload-option:hover {
    background-color: #383838; /* Hover effect */
}

.game-upload-option-icon {
    font-size: 1.8em;
    color: #f60; /* Neocu orange color */
    min-width: 36px; /* Ensure icons take up space even if content is short */
    text-align: center;
}

.game-upload-option-text h3 {
    margin: 0 0 5px 0;
    color: #ffffff;
    font-size: 1em;
}

.game-upload-option-text p {
    margin: 0;
    color: #cccccc;
    font-size: 0.9em;
    line-height: 1.4;
}

.game-upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.game-upload-input,
.game-upload-textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #555;
    background-color: #333;
    color: #ffffff;
    font-size: 1em;
}

.game-upload-textarea {
    resize: vertical;
    min-height: 100px;
}

.game-upload-submit-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #f60; /* Orange submit button */
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: flex-start; /* Reduced width, align left */
}

.game-upload-submit-button:hover {
    background-color: #d45000; /* Darker orange on hover */
}

.upload-error-message {
    color: #ff4d4d; /* Red error message color */
    font-size: 0.9em;
    margin-top: 5px;
}

.game-upload-file-upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.game-upload-file-input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
}

.game-upload-file-label {
    padding: 10px 15px;
    border: 2px dashed #777;
    border-radius: 4px;
    background-color: #333;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
}

.game-upload-file-label:hover {
    background-color: #444;
}

.studio-games-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 10px;
}

.studio-game-card {
    background-color: #282828;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
}

.studio-game-card:hover {
    background-color: #383838;
}

.studio-game-card h3 {
    margin: 0;
    font-size: 1em;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.confirmation-actions,
.submission-actions {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.game-upload-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #f60;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.game-upload-button.studio-button {
    background-color: #f60; /* Neocu orange for studio button */
    color: #ffffff;
}

.game-upload-button.close-button {
    background-color: #555; /* Grey for close button */
}

.game-upload-button:hover {
    background-color: #d45000;
}
.game-upload-button.studio-button:hover {
    background-color: #d45000; /* Darker orange on hover */
}
.game-upload-button.close-button:hover {
    background-color: #777;
}

.public-link-card {
    background-color: #282828;
    border-radius: 4px;
    padding: 10px 15px;
    text-align: center;
}

.public-link-card p {
    margin: 0 0 5px 0;
    color: #cccccc;
    font-size: 0.9em;
}

.public-link {
    color: #f60; /* Neocu orange for public link */
    text-decoration: none;
    font-size: 1em;
    display: block;
    overflow-wrap: break-word;
}

.thumbnail-preview {
    margin-top: 10px;
    text-align: center;
}
.thumbnail-preview img {
    border-radius: 4px;
}

.game-upload-ads-switch {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px; /* Added margin bottom for spacing */
}

.game-upload-ads-label {
    color: #ffffff;
    display: flex; /* Use flexbox to align label and icon */
    align-items: center; /* Vertically align items */
    gap: 5px; /* Space between label text and icon */
}

.game-upload-ads-checkbox {
    /* No default styles needed as toggle switch is custom */
    display: none; /* Hide default checkbox */
}

/* Toggle Switch Styles */
.toggle-switch {
    position: relative;
    width: 60px;
    display: inline-block;
    text-align: left;
    top: 0px;
}

.toggle-switch-checkbox {
    display: none;
}

.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
}

.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}

.toggle-switch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #49bb4c; /* Green color when ON */
    color: #fff;
}

.toggle-switch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #777777; /* Grey color when OFF */
    color: #fff;
    text-align: right;
}

.toggle-switch-switch {
    display: block;
    width: 24px;
    height: 24px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border-radius: 20px;
    transition: right 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}

.toggle-switch-active + .toggle-switch-switch {
    right: 0px; /* Ensure switch is fully to the right when active */
}

.ads-tooltip-icon {
    font-size: 0.9em;
    color: #aaa;
    cursor: help; /* Change cursor to help to indicate tooltip */
}

.confirmation-ads-switch {
    justify-content: center; /* Center the ads switch in confirmation actions */
    margin-bottom: 15px; /* Add some space below */
}